import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() { }

  addSportsHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading = new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  reportedPostsHeading = new BehaviorSubject(false);
  reportedEventsHeading = new BehaviorSubject(false);
  eventsHeading = new BehaviorSubject(false);
  blockedUsersHeading = new BehaviorSubject(false);


}
