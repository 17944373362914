<div id="forgot-password" *ngIf="!submitted">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="login-field text-center">
                    <div class="login-box">
                        <div class="logo"><img src="../../../../assets/ride_loud_icons/image 31.svg" alt="logo"></div>
                        <h3 class="mt-4"><b>Emergency Contact</b></h3>
                        <p class="mb-1">{{name}} wants to add you as an emergency contact.</p>
                        <form action="">
                            <button type="button" class="btn btn-primary" (click)="changestatus(1)">Accept</button>
                            <button type="button" class="btn btn-danger" (click)="changestatus(2)">Decline</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Logout fonfirm -->
<div id="forgot-password" *ngIf="submitted">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-10">
                <div class="login-field text-center">
                    <div class="login-box">
                        <img class="mt-2" src="../../../../assets/ride_loud_icons/image 31.svg" alt="">
                        <p class="mb-1">Please Check your ride loud application.</p>
                        <p class="back-to-app">
                            <a href="https://rideloud0.app.link/TktmWYgLYNb"
                                class="d-flex align-items-center justify-content-center">
                                <img src="../../../../assets/ride_loud_icons/left-arrow.svg" alt="Back Arrow"
                                    class="back-arrow-icon">
                                <span class="ml-1">Back to ride loud application.</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>